import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faFileAlt } from "@fortawesome/free-solid-svg-icons"
import Badge from "../atoms/Badge"
import axios from "axios"
import { getFileTypeLabel, toastHandler } from "../../utils/utils"

const DropzoneBoxWithTitleAndPreview = ({ data, updateStatus, APIToken }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState(data.jobDrives)
  const [badgeState, setBadgeState] = useState(data.status)
  const [numOfAllowedFiles, setNumOfAllowedFiles] = useState(
    Math.max(0, 10 - data.jobDrives.length)
  )

  const onDrop = useCallback(acceptedFiles => {
    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/tiff",
      "image/bmp",
    ]

    setFiles(prevFiles => {
      const availableSlots = numOfAllowedFiles - prevFiles.length
      if (availableSlots <= 0) return prevFiles

      const filteredFiles = acceptedFiles
        .filter(
          file =>
            allowedFileTypes.includes(file.type) &&
            file.size <= 10 * 1024 * 1024
        )
        .slice(0, availableSlots)
        .map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )

      return [...prevFiles, ...filteredFiles]
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
      "image/tiff": [".tiff"],
      "image/bmp": [".bmp"],
    },
  })

  const removeFile = fileName => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName))
    setBadgeState(data.status)
  }

  const handleFileSubmit = async filesToSubmit => {
    setIsSubmitting(true)
    setBadgeState("Uploading")

    try {
      // Request presigned URLs for file uploads
      const response = await axios.post(
        `${process.env.GATSBY_SYNKLI_API_ENDPOINT_PROD}/ethical-clearance/presigned-url`,
        {
          ethical_clearance_doc_id: data.ethical_clearance_doc_id,
          files: filesToSubmit.map(file => ({
            fileName: file.path
              .replace("./", "")
              .replace(/[^a-zA-Z0-9_.-]/g, ""),
            mimeType: file.type,
            fileSize: file.size,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${APIToken}`,
            "Content-Type": "application/json",
          },
        }
      )

      if (response.status === 200 && response.data?.data?.files?.length) {
        let awsPresignedResponseData = {
          ethical_clearance_doc_id: data.ethical_clearance_doc_id,
          files: [],
        }

        // Upload each file to the provided S3 URLs
        const uploadPromises = response.data.data.files.map(
          (fileInfo, index) => {
            const fileToUpload = filesToSubmit[index]
            if (!fileToUpload) {
              console.error(`No file found for upload index: ${index}`)
              return null
            }

            const uploadUrl = fileInfo.url
            const key = fileInfo.key

            return axios
              .put(uploadUrl, fileToUpload, {
                headers: {
                  "Content-Type": fileToUpload.type,
                },
              })
              .then(() => {
                awsPresignedResponseData.files.push({
                  path: key,
                  originalname: fileToUpload.name
                    .replace("./", "")
                    .replace(/[^a-zA-Z0-9_.-]/g, ""),
                  mimetype: fileToUpload.type,
                  size: fileToUpload.size,
                })
              })
              .catch(uploadError => {
                console.error(
                  `Failed to upload file: ${fileToUpload.name}`,
                  uploadError
                )
              })
          }
        )

        await Promise.all(uploadPromises)

        // After all uploads, send metadata to Synkli Server
        const uploadResponse = await axios.post(
          `${process.env.GATSBY_SYNKLI_API_ENDPOINT_PROD}/ethical-clearance/document-upload`,
          awsPresignedResponseData,
          {
            headers: {
              Authorization: `Bearer ${APIToken}`,
              "Content-Type": "application/json",
            },
          }
        )

        if (uploadResponse.status === 200) {
          setIsSubmitting(false)
          setBadgeState("submitted")
          setFiles([])
          updateStatus(uploadResponse?.data?.data?.status)
          toastHandler("You response submitted successfully!", "success")

          // Get new numOfAllowedFiles and uploaded files
          try {
            const response = await fetch(
              `${process.env.GATSBY_SYNKLI_API_ENDPOINT_PROD}/ethical-clearance/get-requested-doc-files?ethical_clearance_doc_id=${data.ethical_clearance_doc_id}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${APIToken}`,
                },
              }
            )

            const result = await response.json()
            if (response.status === 200) {
              setNumOfAllowedFiles(Math.max(0, 10 - result.data.count))
              setUploadedFiles(result.data.list)
            }
          } catch (error) {
            console.error("Error fetching data:", error)
          }
        } else {
          handleApiErrors(uploadResponse)
        }
      } else {
        handleApiErrors(response)
      }
    } catch (error) {
      setIsSubmitting(false)
      setBadgeState("failed")
      setFiles([])
      handleApiErrors(error.response.data)
    }
  }

  // Helper function to handle API errors
  const handleApiErrors = response => {
    if (response?.errors?.length) {
      response.errors.forEach(error => {
        toastHandler(error, "error")
      })
    } else {
      console.error("Unknown error:", response)
      toastHandler("An unknown error occurred.")
    }
  }

  return (
    <div
      className={`rounded-[10px] px-6 md:px-8 py-6 mt-4 text-[14px] border shadow`}
    >
      <div className="flex flex-col items-start justify-between gap-[25px]">
        <div className="flex flex-wrap items-center gap-[5px]">
          <h4 className="text-[16px] md:text-[18px] leading-[1.4em] font-[500]">
            {data.name}
          </h4>
          <Badge text={badgeState} />
        </div>
        {/* Dropzone */}
        <div className="w-full">
          <div
            {...getRootProps()}
            className="border border-dashed border-[#b695f8] rounded-[12px] p-1 w-full text-center cursor-pointer"
          >
            <div className="bg-[#f1ebfa] px-3 py-8 rounded-[8px]">
              <input {...getInputProps()} />
              <FontAwesomeIcon
                icon={faFileAlt}
                className="mx-auto text-3xl text-gray-700"
              />
              <p className="mt-1 text-[15px]">Choose file or Drag it here</p>
            </div>
          </div>
          <p className="text-right text-[#B695F8] mt-2">
            {numOfAllowedFiles}/10
          </p>
        </div>
      </div>

      {/* File Previews */}
      <div
        className={`flex flex-wrap gap-[30px] ${
          files.length > 0 ? "mt-6" : ""
        }`}
      >
        {files.map((file, index) => {
          const { label, icon } = getFileTypeLabel(file)

          return (
            <div
              key={"filetobeupload_" + index}
              className="inline-flex items-center gap-[20px] rounded-lg mb-2"
            >
              <div className="flex items-center">
                {/* Dynamic file type icon and label */}
                <div
                  className={`bg-[#F08201] text-white rounded-lg px-2 py-1 text-[20px] font-semibold text-center w-[40px] mr-2`}
                >
                  <FontAwesomeIcon icon={icon} />
                </div>
                <div className="flex flex-col">
                  <span className="text-[15px]">{file.name}</span>
                  <span className="text-gray-500 text-xs">
                    {(file.size / 1024).toFixed(2)} KB •{" "}
                    {new Date().toLocaleDateString()}
                  </span>
                </div>
              </div>
              <button
                onClick={() => removeFile(file.name)}
                className="ml-auto text-[15px] flex items-center justify-center border-2 border-black rounded-[50%] min-w-[25px] min-h-[25px] text-center"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )
        })}
      </div>

      {files.length > 0 ? (
        <div className="flex justify-end mt-1">
          <button
            onClick={() => handleFileSubmit(files)}
            className="group primary-btn flex items-center justify-center gap-[10px] font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-6 rounded-[10px] border-2 text-center py-1 transition duration-300"
          >
            Submit
            {isSubmitting && (
              <span
                className={`border-4 block border-t-4 border-t-transparent border-white group-hover:border-[#0A1E46] group-hover:border-t-transparent rounded-full w-5 h-5 animate-spin`}
              ></span>
            )}
          </button>
        </div>
      ) : (
        ""
      )}

      {/* Uploaded File Previews */}
      {uploadedFiles && uploadedFiles.length > 0 ? (
        <div className={`${uploadedFiles.length > 0 ? "mt-0" : ""}`}>
          <h4 className="text-[16px] md:text-[18px] leading-[1.4em] font-[500]">
            Uploaded Files
          </h4>
          <div className={`flex flex-wrap gap-[30px] mt-5 bg-[#f8f5fa] px-3 py-5 rounded-[8px]`}>
            {uploadedFiles.map((file, index) => {
              const { label, icon } = getFileTypeLabel(file)

              return (
                <div
                  key={"uploaded_file" + index}
                  className="inline-flex items-center gap-[20px] rounded-lg mb-2"
                >
                  <div className="flex items-center">
                    {/* Dynamic file type icon and label */}
                    <div
                      className={`bg-[#F08201] text-white rounded-lg px-2 py-1 text-[20px] font-semibold text-center w-[40px] mr-2`}
                    >
                      <FontAwesomeIcon icon={icon} />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[15px]">{file.name}</span>
                      <span className="text-gray-500 text-xs">
                        {(file.size / 1024).toFixed(2)} KB •{" "}
                        {new Date().toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default DropzoneBoxWithTitleAndPreview
