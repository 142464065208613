import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faClock, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Badge = ({ text }) => {
  // Mapping badge states to icons, bgColor, and textColor
  const badgeStateStyles = {
    pending: {
      icon: faClock,
      bgColor: "bg-[#F0820115]",  // Red with transparency
      textColor: "#F08201",   // Red
    },
    submitted: {
      icon: faCheckCircle,
      bgColor: "bg-[#008C5115]",  // Green with transparency
      textColor: "#008C51",   // Green
    },
    uploading: {
      icon: faClock,
      bgColor: "bg-[#F1474715]",  // Red with transparency
      textColor: "#F14747",   // Red
    },
    failed: {
      icon: faExclamationTriangle,
      bgColor: "bg-[#F1474715]",  // Red with transparency
      textColor: "#F14747",   // Red
    },
    default: {
      icon: faExclamationTriangle,
      bgColor: "bg-[#d9534f15]",  // Yellow with transparency
      textColor: "#f0ad4e",   // Yellow
    },
  };

  // Convert the text to lowercase to match the state (to avoid case issues)
  const stateKey = text.toLowerCase();

  // Get the corresponding styles, defaulting to 'default' if the state is not found
  const { icon, bgColor, textColor } = badgeStateStyles[stateKey] || badgeStateStyles.default;

  return (
    <div
      className={`${bgColor} text-[${textColor}] max-w-max rounded-full px-5 py-1 text-[12px] flex items-center justify-center capitalize`}
    >
      {text}
      {icon && <FontAwesomeIcon icon={icon} className="ml-2" />}
    </div>
  );
};

export default Badge;
