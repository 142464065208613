import React from "react"

function ClientImageOrInitials({ data }) {
  let initialsHtml

  // Check if the image exists, and if not, generate initials and background color
  if (data.image === null) {
    if (data.first_name && data.last_name) {
      initialsHtml = data.first_name.charAt(0).toUpperCase() + data.last_name.charAt(0).toUpperCase()
    } else {
      // Use the first two letters of `display_name` if `first_name` or `last_name` is missing
      const words = data.display_name.split(" ")
      const firstLetter = words[0] ? words[0].charAt(0) : ""
      const secondLetter = words[1] ? words[1].charAt(0) : ""
      initialsHtml = firstLetter.toUpperCase() + secondLetter.toUpperCase()
    }
  }

  return (
    <div
      className="w-[80px] h-[80px] flex items-center text-white justify-center text-[35px] font-[500] min-w-[80px] min-h-[80px] rounded-[20px] bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: data.image !== null ? `url('${data.image}')` : "",
        backgroundColor: data.image === null ? "#B695F8" : "", // Apply bgColor if no image
      }}
    >
      {/* Display initials if no image */}
      {data.image === null && <div>{initialsHtml}</div>}
    </div>
  )
}

export default ClientImageOrInitials
