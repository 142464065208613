import React, { useState } from "react"
import Badge from "../atoms/Badge"
import DropzoneBoxWithTitleAndPreview from "./DropzoneBoxWithTitleAndPreview"
import ImageRenderer from "../atoms/ImageRenderer"
import MessageSubmitBox from "./MessageSubmitBox"
import ClientImageOrInitials from "../atoms/ClientImageOrInitials"

const ClientDetailsAccordianBox = ({
  data,
  accountant_name,
  ethical_clearance_id,
  message,
  updateStatus,
  APIToken,
}) => {
  const [showClientBoxDetails, setShowClientBoxDetails] = useState(false)

  const handleClientBoxToggle = e => {
    setShowClientBoxDetails(prevState => !prevState)
  }

  return (
    <div className="bg-white rounded-[16px] shadow px-6 md:px-8 py-6">
      <div className="cursor-pointer relative" onClick={handleClientBoxToggle}>
        <div className="absolute right-[15px] top-[15px]">
          {showClientBoxDetails ? (
            <div className="max-w-[30px]" title="Click to minimize">
              <ImageRenderer img="minimize-icon" alt="minimize-icon" />
            </div>
          ) : (
            <div
              className="heart-animation max-w-[30px]"
              title="Click to expand"
            >
              <ImageRenderer img="maximise-icon" alt="maximize-icon" />
            </div>
          )}
        </div>
        <h2 className="text-[22px] leading-[1.2em] font-[600] text-black">
          Client Detail
        </h2>
        <div className="flex flex-col md:flex-row md:items-center mt-7 gap-[20px]">
          <ClientImageOrInitials data={data.business_client} />
          <div className="flex flex-col gap-[25px]">
            <div className="flex flex-wrap items-center gap-[10px]">
              <h4 className="text-[16px] leading-[1.2em] text-black">
                {data.business_client.first_name &&
                data.business_client.last_name
                  ? `${data.business_client.first_name} ${data.business_client.last_name}`
                  : data.business_client.display_name}
              </h4>
              {data.business_client.abn &&
              data.business_client.abn !== "null" ? (
                <div
                  className={`bg-[#F1474715] text-[#F14747] max-w-max rounded-full px-5 py-1 text-[12px] flex items-center justify-center capitalize`}
                >
                  {`ABN: ${data.business_client.abn}`}
                </div>
              ) : (
                ""
              )}
              {data.business_client.tfn &&
              data.business_client.tfn !== "null" ? (
                <div
                  className={`bg-[#F0820115] text-[#F08201] max-w-max rounded-full px-5 py-1 text-[12px] flex items-center justify-center capitalize`}
                >
                  {`TFN: ${data.business_client.tfn}`}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-wrap gap-[20px] lg:gap-[60px] text-[14px] leading-[1.2em]">
              {data.business_client.email && (
                <div className="flex flex-wrap gap-[10px] lg:gap-[60px]">
                  <span className="text-[#666666]">Email Address:</span>
                  <span className="text-[#000000]">
                    {data.business_client.email}
                  </span>
                </div>
              )}
              {data.business_client.phone_number && (
                <div className="flex flex-wrap gap-[10px] lg:gap-[60px]">
                  <span className="text-[#666666]">Phone Number:</span>
                  <span className="text-[#000000]">
                    {data.business_client.phone_number}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`${showClientBoxDetails === true ? "show" : "hidden"}`}>
        <div className="max-w-[90%] mx-auto my-7 bg-[#ebebeb] p-[1px]"></div>

        <h2 className="text-[20px] leading-[1.2em] font-[600] text-black mt-7">
          Ethical Clearance
        </h2>

        {/* Message Section - Conditionally Rendered */}
        <MessageSubmitBox
          data={data}
          ethical_clearance_id={ethical_clearance_id}
          updateStatus={updateStatus}
          APIToken={APIToken}
        />

        <div>
          <h2 className="text-[20px] leading-[1.2em] font-[600] text-black mt-7">
            Message from {accountant_name}
          </h2>
          <p
            className="bg-[#f8f5fa] rounded-[10px] mt-8 p-6 text-[14px]"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>

        <h2 className="text-[20px] leading-[1.2em] font-[600] text-black mt-7">
          Requested Document(s)
        </h2>

        {data.documents.map((item, index) => (
          <DropzoneBoxWithTitleAndPreview
            key={"dropzone_" + index}
            data={item}
            updateStatus={updateStatus}
            APIToken={APIToken}
          />
        ))}
      </div>
    </div>
  )
}

export default ClientDetailsAccordianBox
